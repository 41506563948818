import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null}>
          <Grid
            stackable={true}
            className={"component-section-container catering-page"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
          >
            <Grid.Column width={8}>
              <h1>Catering</h1>
              <p>
                Get ready to experience the tantalizing flavors of Carolina BBQ
                style cuisine with Junebug Carolina Flare. Our food truck offers
                a unique catering service that is perfect for any event or
                occasion. Whether you are planning a corporate event, wedding,
                or backyard BBQ, our team of experienced and friendly staff are
                ready to provide you with exceptional service and delicious food
                that will satisfy your guests and leave them craving for more.
              </p>
              <p>
                Our catering menu is customizable to meet your specific needs,
                ensuring that we provide you with a memorable and satisfying
                dining experience. We take pride in our commitment to providing
                excellent service, making sure that your event is stress-free
                and enjoyable. Our team takes care of all the details, including
                setting up, serving, and cleaning up, so you can focus on
                hosting and mingling with your guests.
              </p>
              <p>
                We are passionate about delivering the ultimate BBQ experience
                that will leave a lasting impression on you and your guests.
              </p>
              <h3>
                So why wait? Contact us today to book us for your next event or
                catering service, and get ready to enjoy Junebug’s good eats!
              </h3>
              <ContactForm
                constructMessage={createDefaultMessageFromState}
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "address",
                    displayLabel: "Address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "guest",
                    displayLabel: "Number Of Guests",
                    placeholder: "Number Of Guests",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "eventDate",
                    displayLabel: "Event Date",
                    placeholder: "Event Date",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "startTime",
                    displayLabel: "Start Time",
                    placeholder: "Start Time",
                    type: "input",
                    inputType: "time",
                    required: false,
                  },
                  {
                    label: "endTime",
                    displayLabel: "End Time",
                    placeholder: "End Time",
                    type: "input",
                    inputType: "time",
                    required: false,
                  },
                  {
                    label: "message",
                    placeholder: "Additional Message",
                    type: "textarea",
                    required: false,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Catering" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
